import gql from'graphql-tag';import{micrositeFragment}from'./Fragments';export const ListMicrositeQuery=gql`
	query ListMicrositeQuery($subjectId: String!) {
		listMicrosites(where: {subjectId: {equals: $subjectId}}) {
			...microsite
		}
	}
	${micrositeFragment}
`;export const listSnippetQuery=gql`
	query ListMicrositeQuery($subjectId: String!) {
		listMicrosites(where: {subjectId: {equals: $subjectId}}) {
			id
			snippetLogo {
				id
				secret
			}
			snippetBackground {
				id
				secret
			}
			publishSnippet
			publishGroupSnippet
			snippetColor
			snippetButtonText
			snippetButtonLink
			snippetButtonColor
			snippetNoteLabel
			snippetNotePlaceholder
			snippetNoteRequired
			snippetStaffSelect
		}
	}
`;export const createMicrositeMutation=gql`
	mutation CreateMicrositeMutation(
		$subjectId: SubjectWhereUniqueInput!
		$heading: String
		$facebookId: String
		$publish: Boolean
		$endtext: String
		$website: String
		$template: String
		$logoConn: FileWhereUniqueInput
		$fImgConn: FileWhereUniqueInput
		$caroConn: [FileWhereUniqueInput!]
		$meta: AWSJSON
	) {
		createMicrosite(
			data: {
				subject: {connect: $subjectId}
				heading: $heading
				facebookId: $facebookId
				publish: $publish
				endtext: $endtext
				website: $website
				template: $template
				meta: $meta
				logo: {connect: $logoConn}
				frontImg: {connect: $fImgConn}
				carousel: {connect: $caroConn}
			}
		) {
			...microsite
		}
	}
	${micrositeFragment}
`;export const updateMicrositeMutation=gql`
	mutation UpdateMicrositeMutation(
		$micrositeId: String!
		$heading: String
		$facebookId: String
		$publish: Boolean
		$endtext: String
		$website: String
		$template: String
		$logoConn: FileWhereUniqueInput
		$fImgConn: FileWhereUniqueInput
		$caroConn: [FileWhereUniqueInput!]
		$meta: AWSJSON
	) {
		updateMicrosite(
			where: {id: $micrositeId}
			data: {
				heading: $heading
				facebookId: $facebookId
				publish: $publish
				endtext: $endtext
				website: $website
				template: $template
				meta: $meta
				logo: {connect: $logoConn}
				frontImg: {connect: $fImgConn}
				carousel: {set: $caroConn}
			}
		) {
			...microsite
		}
	}
	${micrositeFragment}
`;export const createSnippetMutation=gql`
	mutation CreateSnippetMutation(
		$subjectId: SubjectWhereUniqueInput!
		$sLogoConn: FileWhereUniqueInput
		$sBgConn: FileWhereUniqueInput
		$snippetColor: String
		$snippetButtonText: String
		$snippetButtonLink: String
		$snippetButtonColor: String
		$publishSnippet: Boolean
		$publishGroupSnippet: Boolean
		$snippetNoteLabel: String
		$snippetNotePlaceholder: String
		$snippetNoteRequired: Boolean
		$snippetStaffSelect: Boolean
	) {
		createMicrosite(
			data: {
				subject: {connect: $subjectId}
				snippetLogo: {connect: $sLogoConn}
				snippetBackground: {connect: $sBgConn}
				snippetColor: $snippetColor
				snippetButtonText: $snippetButtonText
				snippetButtonLink: $snippetButtonLink
				snippetButtonColor: $snippetButtonColor
				publishSnippet: $publishSnippet
				publishGroupSnippet: $publishGroupSnippet
				snippetNoteLabel: $snippetNoteLabel
				snippetNotePlaceholder: $snippetNotePlaceholder
				snippetNoteRequired: $snippetNoteRequired
				snippetStaffSelect: $snippetStaffSelect
			}
		) {
			snippetLogo {
				id
				secret
			}
			snippetBackground {
				id
				secret
			}
			publishSnippet
			publishGroupSnippet
			snippetColor
			snippetButtonText
			snippetButtonLink
			snippetButtonColor
			snippetNoteLabel
			snippetNotePlaceholder
			snippetNoteRequired
			snippetStaffSelect
		}
	}
`;export const updateSnippetMutation=gql`
	mutation UpdateMicrositeMutation(
		$micrositeId: String!
		$sLogoConn: FileWhereUniqueInput
		$sBgConn: FileWhereUniqueInput
		$snippetColor: String
		$snippetButtonText: String
		$snippetButtonLink: String
		$snippetButtonColor: String
		$publishSnippet: Boolean
		$publishGroupSnippet: Boolean
		$snippetNoteLabel: String
		$snippetNotePlaceholder: String
		$snippetNoteRequired: Boolean
		$snippetStaffSelect: Boolean
	) {
		updateMicrosite(
			where: {id: $micrositeId}
			data: {
				snippetLogo: {connect: $sLogoConn}
				snippetBackground: {connect: $sBgConn}
				snippetColor: $snippetColor
				snippetButtonText: $snippetButtonText
				snippetButtonLink: $snippetButtonLink
				snippetButtonColor: $snippetButtonColor
				publishSnippet: $publishSnippet
				publishGroupSnippet: $publishGroupSnippet
				snippetNoteLabel: $snippetNoteLabel
				snippetNotePlaceholder: $snippetNotePlaceholder
				snippetNoteRequired: $snippetNoteRequired
				snippetStaffSelect: $snippetStaffSelect
			}
		) {
			snippetLogo {
				id
				secret
			}
			snippetBackground {
				id
				secret
			}
			publishSnippet
			publishGroupSnippet
			snippetColor
			snippetButtonText
			snippetButtonLink
			snippetButtonColor
			snippetNoteLabel
			snippetNotePlaceholder
			snippetNoteRequired
			snippetStaffSelect
		}
	}
`;